import styled from "styled-components";
import homeBg from "../../statics/images/home-bg.webp";

export const HomeContainer = styled.section`
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(${homeBg}) center / cover no-repeat;
    padding: 8rem 2rem 2rem 2rem;

    @media screen and (max-width: 800px){
        flex-direction: column;
        gap: 2rem;
        padding: 10rem 2rem 2rem 2rem;
    }
`;

export const ContainerButtons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 25%;

    .buttons__card{
        width: 100%;
        transition:500ms;
        display:flex;
        align-items: center;
        justify-content: center;
        
        .card__content{
            width: 60%;
            background: rgba(241, 240, 240, 0.8);
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 1rem;
            border-radius: 5px;
            filter: drop-shadow(5px 5px 10px rgba(0,0,0,0.25));
            cursor: pointer;

            .content__svg{
                display: none;
            }
        }

        .selected{
            background: #C6C6C6 ;
        }
    }

    @media screen and (max-width: 800px){
        order: 1;
        width: 100%;

        .buttons__card{
            .card__content{
                width: 80%;
                flex-direction: row;
                gap: 1rem;
                justify-content: center;

                .content__svg{
                    display: none;
                }
            }
        }

    }
`;

export const ContainerContent = styled.div`
    transform-origin: top;
    transform: scaleY(${props=>props.open ? 1 : 0});
    transition: 500ms;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    min-height: 30rem;
    background: rgba(241, 240, 240, 0.8);
    border-radius: 5px;
    padding: 1.25rem;

    .content__img{
        width: 40%;

        img{
            max-width: 100%;
            max-height: 75vh;
        }
    }

    .content__text{
        width: 55%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:2.5rem;
        
    }

    @media screen and (max-width: 800px){
        flex-direction: column;
        width: 100%;

        .content__img{
            width: 100%;
        }

        .content__text{
            width: 100%;
            align-items: flex-start;

            a{
                align-self: center;
            }
        }
    }
`;