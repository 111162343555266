import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

    *{
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        line-height: normal;
    }

    html{
        scroll-behavior: smooth;
    }

    h1, h2, h3, h4{
        font-family: 'Rubik', sans-serif;
    }

    h1{
        font-size: 2rem;
    }
    h2{
        font-size: 1.75rem;
    }
    h3{
        font-size: 1.5rem;
    }

    p, li, a, span{
        font-family: 'Manrope', sans-serif;
        font-size: 1.25rem;
    }

    a{
        text-decoration: none;
        color: inherit;
    }

    .container__pagination{
        display: flex;
        list-style: none;
        gap: 1.25rem;
        background: rgba(241, 240, 240, 0.8);
        padding: 0.5rem;
        border-radius: 5px;
    }

    .pagination__item{
        padding: 0.25rem 0.5rem;
        border-radius: 5px;
        cursor: pointer;
    }

    .pagination__active{
        background: lightblue;
    }

    .pagination__previous{
        cursor: pointer;
    }

    .pagination__next{
        cursor: pointer;
    }

    @media screen and (max-width: 800px) {
        .container__pagination{
            flex-shrink:1;
            flex-wrap: wrap;
        }
    }
`;

export default GlobalStyle;