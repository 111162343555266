import { CardContainer, ContainerContent, ContainerImage } from "./styles"
import {ReactComponent as showMoreSymbol} from "../../../statics/images/showmore.svg";
import { Link } from "react-router-dom";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useEffect, useState } from "react";

const ComponentCard =  ({
    item, 
    showMore, 
    redirectTo, 
    hideTitle, 
    hideDesc, 
    entireImage,
    centerImage, 
    removeBold=false
})=>{
    const [description, setDescription] = useState(null);

    useEffect(()=>{
        if(removeBold){
            setDescription(item?.descricao?.replaceAll(/\*+/gmi, ""));
        }else{
            setDescription(item?.descricao);
        }
    },[item])

    return(
        <CardContainer>
            <ContainerImage centerImage={centerImage} entireImage={entireImage}>
                <img src={item.capa.url}/>
            </ContainerImage>

            {(!hideDesc || !hideTitle) && (<ContainerContent>
                <div className="content__text">
                    {!hideTitle && <p className="text__title">{item.titulo}</p>}
                    {showMore && (
                        <div className="content__showmore">
                            <Link to={redirectTo} className="showmore__symbol">
                                <p>+</p>
                            </Link>
                        </div>
                    )}
                </div>
                
                {!hideDesc && <ReactMarkdown className="content__desc" children={description}/>}
            </ContainerContent>)}

        </CardContainer>
    )
}

export default ComponentCard;