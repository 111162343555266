import styled from "styled-components";


export const HeaderContainer = styled.header`
    position: fixed;
    top:2rem;
    left: 2rem;
    right: 2rem;
    background: rgba(241, 240, 240, 0.8);
    border-radius: 5px;
    padding: 1rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    filter: drop-shadow(5px 5px 10px rgba(0,0,0,0.25));
    z-index: 2;

    @media screen and (max-width: 800px){
        padding: 1rem 1rem;
    }
`;

export const ContainerLogo = styled.div`
    width: 30%;
    display: flex;
    align-items: center;

    .language{
        display: none;
    }

    .logo{
        max-height: 100%;
        width: 100%;
        margin: -10%;
    }

    @media screen and (max-width: 800px){
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .language{
            display: block;
            flex-shrink: 0;
            width: 40%;
        }

        .logo{
            width: 100%;
            max-height: 100%;
            max-height: 100%;
        }
    }
`;

export const ContainerNavigation = styled.div`
    display: flex;
    align-items: center;
    gap: 1.5rem;
    white-space: nowrap;

    a{
        font-weight: 600;
        transition: 500ms;

        :hover{
            color: blue;
        }
    }

    @media screen and (max-width: 600px){
        display: none;
    }
`;