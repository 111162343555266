import { useQuery } from "@apollo/client";
import { ContainerContent, ContainerFilters, PortfolioContainer } from "./styles";
import { useContext, useEffect, useState } from "react";
import ComponentFilter from "../../common/components/filter";
import ComponentCard from "../../common/components/card";
import queryPortfolio from "../../service/queryPortfolio";
import ComponentPoupUpToArt from "../../common/components/poupUpToArt";
import { LanguageContext } from "../../common/contexts/languageContext";
import Pagination from "../../common/components/pagination";
import pdf from "../../statics/portfolio.pdf";

const PagePortfolio = () => {
    const [years, setYears] = useState(null);
    const [categories, setCategories] = useState(null);
    const [yearSelected, setYearSelected] = useState(null);
    const [categorySelected, setCategorySelected] = useState(null);
    const [cardSelected, setCardSelected] = useState(null);
    const [currentPageData, setCurrentPageData] = useState(null);
    const [currentData, setCurrentData] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const { languageSelected } = useContext(LanguageContext);
    const { loading, error, data: portfolioData } = useQuery(queryPortfolio(yearSelected, languageSelected));

    useEffect(() => {
        if (portfolioData) {
            getYears();
            getCategories();
            handleCurrentdata();
            setCurrentPage(0);
        }
    }, [portfolioData]);

    useEffect(() => {
        handleCurrentdata();
        setCurrentPage(0);
    }, [categorySelected])

    useEffect(() => {
        if (currentData) {
            const maxIndex = currentPage * 12 + 12 > currentData?.length ?
                currentData?.length
                : currentPage * 12 + 12;

            //console.log("length: ", currentData?.length, "current page: ", currentPage, "max index: ", maxIndex)

            setCurrentPageData([...currentData.slice(currentPage * 12, maxIndex)]);
        }
    }, [currentPage, currentData])

    const getYears = () => {
        const yearsList = portfolioData?.portfolios?.map(project => project.ano);
        const yearsSet = [... new Set(yearsList)];
        setYears(yearsSet);
    }

    const getCategories = () => {
        const categoriesList = portfolioData?.portfolios?.map(project => project.categoria);
        const categoriesSet = [... new Set(categoriesList)];
        setCategories(categoriesSet)
    }

    const handleCurrentdata = () => {
        if (categorySelected) {
            const dataFiltered = portfolioData?.portfolios?.filter(item => item.categoria == categorySelected);
            setCurrentData(dataFiltered);
        } else {
            setCurrentData(portfolioData?.portfolios);
        }
    }

    const getImages = () => {
        const filtered = portfolioData?.portfolios?.filter(item => item.id == cardSelected)[0];
        const images = filtered?.capa?.url;
        return [images]
    }

    if (loading) return <p>Loading ...</p>;
    if (error) console.log(error);

    return (
        <PortfolioContainer>
            {/* <ComponentPoupUpToArt images={getImages()} setOpen={setCardSelected} open={cardSelected}/>

            <ContainerFilters>
                <div className="filters__item">
                    {years && <ComponentFilter 
                        name={languageSelected == "pt-br" ? "ano" : "year"} 
                        itemList={["2021", "2022", "2023"]}
                        selected={yearSelected}
                        setSelected={setYearSelected}
                    />}
                </div>

                <div className="filters__item">
                    {categories && <ComponentFilter 
                        name={languageSelected == "pt-br" ? "Suportes" : "Categories"} 
                        itemList={languageSelected == "pt-br" ? ["fotografia", "instalação", "pintura"] : ["photography", "installation", "painting"]}
                        selected={categorySelected}
                        setSelected={setCategorySelected}
                    />}
                </div>           
            </ContainerFilters>

            <ContainerContent>
                {currentPageData && (currentPageData?.map(item=>(
                    <div onClick={()=>setCardSelected(item.id)} className="card">
                        <ComponentCard 
                            redirectTo={""} 
                            item={item}
                            showMore={false}
                            hideTitle={true}
                            removeBold={true}
                            centerImage={true}
                        />
                    </div>
                )))}
            </ContainerContent>

            {currentData?.length>12 && <div className="pagination">
                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} page={Math.ceil((currentData?.length/12))}/>
            </div>} */}

            <object data={pdf} type="application/pdf" style={{ width: "95vw", height: "78vh" }}>
                <div style={{ width: "100vw", height: "78vh", padding: "0 15vw" ,display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <p>{languageSelected == "pt-br" ? "Infelizmente nao foi possível exibir o pdf no seu navegador. Por favor Faça o download no link abaixo." : "Unfortunately, it was not possible to display the PDF in your browser. Please download it from the link below."}</p>
                    <a style={{marginTop: "2rem", padding: "1rem", background: "#808080", color: "#fff", borderRadius: "10px"}} href={pdf} target="_blank" rel="noopener noreferrer">Portfolio PDF</a>
                </div>
            </object>
        </PortfolioContainer>
    )
}

export default PagePortfolio;