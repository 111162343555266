import styled from "styled-components";

export const FilterContainer = styled.section`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap:1rem;
    position: relative;
    cursor: pointer;

    .mainbox{
        width: 100%;
        padding: 1rem;
        background: ${props => props.withoutBg ? "none": "rgba(241, 240, 240, 0.8)"};
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 1rem;

        svg{
            transition: 500ms;
            transform-style:preserve-3d;
            transform-origin: 50%;
            transform: rotate(${props=>props.open ? "180deg" : "0deg"});
        }
    }
    
    .dropdown{
        transition: 500ms;
        position: absolute;
        top: 100%;
        right: 50%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        transform-origin: top;
        transform: scaleY(${props=>props.open ? 1 : 0}) translateX(50%);
        padding: 1rem;
        background: #F1F0F0;
        border-radius: 10px;

        .selected{
            color: blue;
        }
    }
`;