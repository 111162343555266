import {gql} from "@apollo/client"

const queryProjectBySlug = (slug)=>gql`
    query FavProject {
        projeto(where: {link: "${slug}"}){
            curatorial
            galeria (first: 100){
                url
                id
                fileName
            }
            id
            titulo
        }
    }
`;

export default queryProjectBySlug;