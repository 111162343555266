import { ContactContainer, ContainerForm } from "./styles"
import {ReactComponent as Instagram} from "../../statics/images/instagram2.svg";
import {ReactComponent as Email} from "../../statics/images/email.svg";
import React, { useContext, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { LanguageContext } from "../../common/contexts/languageContext";

const PageContact = ()=>{
    const form = useRef();
    const [open, setOpen] = useState(false);
    const [okMessage, setOkMessage] = useState(null);
    const {languageSelected} = useContext(LanguageContext);

    const handleSubmit = async (event)=>{
        event.preventDefault();
        const response = await emailjs.sendForm('service_1yynlrr', 'template_veotdt5', form.current, 'BbzAjbUDwOIxLbxi9');

        if(response.status<300){
            setOkMessage(languageSelected == "pt-br" ? "enviado com sucesso!" : "has been sucessfully sent")
        }else{
            setOkMessage(languageSelected == "pt-br" ? "Houve algum erro, reinicie a página e tente novamente!" : "Something got wrong, refresh the page and try again!")
        }
    }

    return(
        <ContactContainer open={open}>
            <div className="poupup">
                <div className="poupup__content">
                    <p onClick={()=>setOpen(false)} className="close">X</p>

                    {!okMessage ? (<ContainerForm ref={form} onSubmit={handleSubmit}>
                        <input name="from_name" placeholder={languageSelected == "pt-br" ? "Nome Completo" :  "Full Name"}/>
                        <input name="from_email" placeholder="E-mail"/>
                        <textarea name="message" placeholder={languageSelected == "pt-br" ?"Escreva uma mensagem" : "Write a message"}/>
                        <button type="submit">{languageSelected == "pt-br" ? "Enviar" :  "Send"}</button>
                    </ContainerForm>)
                    :(<p>{okMessage}</p>)}
                </div>
            </div>

            <a className="address" target="__blank" href="https://goo.gl/maps/7GsGLeL3et2EojZs7">{languageSelected == "pt-br" ? "Rua Joaquim Lírio, 820, loja 46, Ed. The Point Plaza praia do canto, Vitória ES. CEP 29055460" : "Street Joaquim Lírio, 820, store 46. <br/> Ed. The Point Plaza praia do canto, Vitória ES. Zipcode 29055460"}</a>

            <div className="container__content">
                {/*<p onClick={()=>setOpen(true)} className="content__btn">{ languageSelected == "pt-br" ? "Agende uma Visita" :  "Schedule a turn to visit"}</p>*/}

                <a target="__blank" href="https://www.instagram.com/samirapavesi/" className="content__media">
                    <Instagram/>
                </a>

                <a target="__blank" href="mailto:contato@samirapavesi.com" className="content__media">
                    <Email/>
                </a>
            </div>
        </ContactContainer>
    )
}

export default PageContact