import { useQuery } from "@apollo/client";
import queryProjetos from "../../service/queryProjetos";
import { ContainerContent, ContainerFilters, ProjectsContainer } from "./styles";
import { useContext, useEffect, useState } from "react";
import ComponentFilter from "../../common/components/filter";
import ComponentCard from "../../common/components/card";
import { LanguageContext } from "../../common/contexts/languageContext";

const PageProjects = ()=>{
    const [years, setYears] = useState(null);
    const [yearSelected, setYearSelected] = useState(null);
    const {languageSelected} = useContext(LanguageContext);
    const { loading, error, data: projectData } = useQuery(queryProjetos(yearSelected, languageSelected));

    useEffect(()=>{
        if(projectData){
            getYears();
        }
    }, [projectData]);

    const getYears =()=>{
        const yearsList = projectData?.projetos?.map(project=> project.ano);
        const yearsSet = [... new Set(yearsList)];
        setYears(yearsSet);
    }

    if(loading) return <p>Loading ...</p>;
    if(error) console.log(error);

    return(
        <ProjectsContainer>
            <ContainerFilters>
                <div className="filters__item">
                    {years && <ComponentFilter 
                        name={languageSelected == "pt-br" ? "ano" : "year"} 
                        itemList={["2021", "2022", "2023"]}
                        selected={yearSelected}
                        setSelected={setYearSelected}
                    />}
                </div>               
            </ContainerFilters>

            <ContainerContent>
                {projectData && projectData.projetos?.map(item=>(
                    <div className="card">
                        <ComponentCard 
                            redirectTo={`/project/${item.link}`} 
                            item={item} 
                            showMore={true}
                            entireImage={true}
                        />
                    </div>
                ))}
            </ContainerContent>
        </ProjectsContainer>
    )
}

export default PageProjects;