import styled from "styled-components";
import portfolioBg from "../../statics/images/portfolio-bg.webp";

export const PortfolioContainer = styled.section`
    width: 100%;
    padding: 8rem 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1.25rem;
    //background: url(${portfolioBg}) center /cover no-repeat;
    background: #fefefe;
    min-height: 100vh;

    >section, >div{
        max-width: 1440px;
    }

    .pdf{
        width: 100%;
        height: 80vh;
    }

    @media screen and (max-width: 800px){
        padding:  8rem 1rem 1rem 1rem;
    }
`;

export const ContainerFilters = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap:1rem;

    .filters__item{
        width: auto;
    }

    @media screen and (max-width: 800px){
        justify-content: flex-start;
    }
`;

export const ContainerContent = styled.section` 
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 1%;
    flex-wrap: wrap;

    .card{
        width: 24.25%;
        margin-bottom: 1.25rem;
        cursor: pointer;
        transition: 500ms;

        :hover{
            filter: drop-shadow(5px 5px 10px rgba(0,0,0,0.25));
        }
    }

    @media screen and (max-width: 800px){
        justify-content: space-between;
        gap: 1.25rem;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;

        .card{
            width: 100%;
            margin-bottom: 0rem;
        }
    }
`;