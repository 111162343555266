import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";


const Pagination = ({ page, currentPage, setCurrentPage }) => {
    console.log(page)
    const handlePageClick = (data) => {
        setCurrentPage(data.selected)
        window.scrollTo(0,0)
    };
  
    return (
      <ReactPaginate
        breakLabel="..."
        nextLabel="próximo >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        pageCount={page}
        previousLabel="< anterior"
        renderOnZeroPageCount={null}
        containerClassName= "container__pagination"
        pageClassName= "pagination__item"
        pageLinkClassName= ""
        previousClassName= "pagination__previous"
        previousLinkClassName= ""
        nextClassName= "pagination__next"
        nextLinkClassName= ""
        breakClassName= ""
        breakLinkClassName=""
        activeClassName="pagination__active"
        forcePage={currentPage}

      />
    );
  };
  
  export default Pagination;