import styled from "styled-components";
import projectsBg from "../../statics/images/projects-bg.webp";

export const ProjectsContainer = styled.section`
    width: 100%;
    padding: 8rem 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap:1.25rem;
    align-items: center;
    justify-content: flex-start;
    //background: url(${projectsBg}) center /cover no-repeat;
    background: #fefefe;
    min-height: 100vh;

    >section, >div{
        max-width: 1440px;
    }

    @media screen and (max-width: 800px){
        padding:  8rem 1rem 1rem 1rem;
    }
`;

export const ContainerFilters = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 800px){
        justify-content: flex-start;
    }
`;

export const ContainerContent = styled.section` 
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1%;

    .card{
        width: 24.25%;
        margin-bottom: 1.25rem;
    }

    @media screen and (max-width: 800px){
        justify-content: space-between;
        gap: 1.25rem;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;

        .card{
            width: 100%;
            margin-bottom: 0rem;
        }
    }
`;