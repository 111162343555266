import styled from "styled-components";
import aboutBg from "../../statics/images/about-bg.webp";

export const AboutUsContainer = styled.section`
    padding: 8rem 2rem 0 2rem;
    //background: url(${aboutBg}) center/cover no-repeat;
    background: #fefefe;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;

    >section, >div{
        max-width: 1440px;
    }
`;

export const ContainerFilter = styled.div`
    width: auto;
    align-self: flex-end;

    @media screen and (max-width: 800px){
        align-self: flex-start;
        z-index: 5;
    }
`;

export const ContainerContent = styled.div`
    flex-grow: 1;
    margin-top: 2rem;
    padding: 2rem;
    max-height: 70vh;
    background: rgba(241, 240, 240, 0.8);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;

    .content__image{
        width: 35%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        border-radius: 10px;

        img{
            max-width: 100%;
            transform: translateY(-5%);
        }
    }

    .content__text{
        width: 62.5%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        overflow-y: scroll;
        padding-right: 1rem;

        >h1{
            font-weight: normal;
        }
    }

    @media screen and (max-width: 800px){
        flex-direction: column;
        gap: 1.25rem;

        .content__image{
            width: 100%;
            min-height: 30vh;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            overflow: hidden;
            border-radius: 10px;

            img{
                max-width: 100%;
                transform: translateY(-5%);
            }
        }

        .content__text{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            overflow-y: scroll;
            padding-right: 1rem;
        }
    }
`;