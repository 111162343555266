import { Link } from "react-router-dom";
import { FooterContainer } from "./styles"
import {ReactComponent as Hamburguer} from "../../../statics/images/burguer.svg";
import {ReactComponent as Instagram} from "../../../statics/images/instagram.svg";
import { useContext, useState } from "react";
import { LanguageContext } from "../../contexts/languageContext";
import ComponentFilter from "../filter";

const ComponentFooter = ()=>{
    const [open, setOpen] = useState(false);
    const {languageSelected, setLanguageSelected} = useContext(LanguageContext);

    return(
        <FooterContainer open={open}>
            <div onClick={()=>setOpen(false)} className="navigation">
                <Link to="/">Home</Link>
                <Link to="projects">{languageSelected == "pt-br" ? "Exposições" : "Exhibitions"}</Link>
                <Link to="portfolio">{languageSelected == "pt-br" ? "Portfólio" : "Portfolio"}</Link>
                <Link to="about">{languageSelected == "pt-br" ? "Biografia" : "Biography"}</Link>
                <Link to="media">{languageSelected == "pt-br" ? "Mídia" : "Press"}</Link>
                <Link to="contact">{languageSelected == "pt-br" ? "Contato" : "Contact"}</Link>
            </div>

            <div className="social-media">
                <a target="__black" href="https://www.instagram.com/samirapavesi/">Samira Pavesi</a>
                <Instagram/>
            </div>

            <div onClick={()=>setOpen(!open)} className="burguer">
                <Hamburguer/>
            </div>
        </FooterContainer>
    )
}

export default ComponentFooter;