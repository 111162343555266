import {gql} from "@apollo/client"

const queryProjetos = (year, language)=>gql`
    query Projetos {
        projetos (where: ${year ? `{linguagem: "${language}", ano: ${year}}, orderBy: data_DESC, first: 200` : `{linguagem: "${language}"}, orderBy: data_DESC, first: 200`}){
            ano
            descricao
            id
            link
            titulo
            capa {
                url
            }
        }
    }
`;

export default queryProjetos;