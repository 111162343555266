import {gql} from "@apollo/client"

const queryMedias = (year)=>gql`
    query queryMedias{
        midias (where: ${year ? `{ano: ${year}}, orderBy: data_DESC, first: 200` : `{}, orderBy: data_DESC, first: 200`}){
            ano
            descricao
            id
            link
            titulo
            capa {
                id
                url
            }
        }
    }
`;

export default queryMedias;