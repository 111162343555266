import styled from "styled-components";


export const FooterContainer = styled.footer`
    display: none;

    @media screen and (max-width:800px){
        display: flex;
        flex-direction: column;
        height: 100vh;
        background: #F1F0F0;
        position: fixed;
        top:0;
        left: 40%;
        right: 0;
        padding: 2rem;
        transition: 500ms;
        transform: translateX(${props=>props.open ? "0%" : "100%"});
        filter: drop-shadow(-5px 5px 10px rgba(0,0,0,0.25));
        z-index: 10;

        .navigation{
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1rem;
            height: 95%;
            
            a{
                font-size: 1.5rem;
            }
        }

        .social-media{
            height: 5%;
            display: flex;
            align-items: center;
            gap: 1.25rem;

            a{
                font-size: 1rem;
            }

            svg{
                width: 32px;
                height: auto;
            }
        }

        .burguer{
            position: absolute;
            bottom:20%;
            left: -6rem;
            background: #F1F0F0;
            padding: 1rem 2.5rem;
            border-radius: 50px 0 0 50px;
            filter: drop-shadow(-5px 0px 5px rgba(0,0,0,0.25));
        }
    }
`;