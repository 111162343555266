import {gql} from "@apollo/client"

const queryAboutPage = (language)=>gql`
  query QueryAboutPage {
    abouts (where: {linguagem: "${language}"}){
      capa {
        id
        url
      }
      id
      texto
      titulo
    }
  }
`;

export default queryAboutPage;