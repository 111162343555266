import { useState } from "react"
import { FilterContainer } from "./styles"
import { ReactComponent as ArrowDown} from "../../../statics/images/arrow-down.svg";

const ComponentFilter = ({
    startOpen = false, 
    name, 
    itemList, 
    selected, 
    setSelected, 
    avoidClear,
    withoutBg
})=>{
    const [open, setOpen] = useState(startOpen);

    return(
        <FilterContainer withoutBg={withoutBg} onMouseLeave={()=>setOpen(false)} onMouseEnter={()=>setOpen(true)} open={open}>
            <div onClick={()=>setOpen(!open)} className="mainbox">
                <p>{selected || name}</p>
                <ArrowDown/>
            </div>

            <div className="dropdown" onClick={()=>{setOpen(false);}}>
                {(selected && !avoidClear) && (<p onClick={()=>{setSelected(null)}}>Limpar</p>)}

                {itemList?.map(item=>(
                    <p className={`${selected == item && "selected"}`} onClick={()=>{setSelected(item)}} key={item}>{item}</p>
                ))}
            </div>
        </FilterContainer>
    )
}

export default ComponentFilter;