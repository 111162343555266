import styled from "styled-components";

export const ProjectContainer =  styled.section`
    display: flex;
    flex-direction: column;
    gap: 5rem;
    padding: 10rem 2rem 2rem 2rem;
    background: #F1F0F0;
    min-height: 100vh;
`;

export const ContainerTexts = styled.div`
    display: flex;
    flex-direction:column;
    gap: 1rem;

    h1{
        text-align: center;
    }

    .text__body{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
`;

export const ContainerImagesList = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;

    img, video{
        width: auto;
        height: 50vh;
    }

    @media screen and (max-width: 800px) {
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        gap: 1.25rem;

        img, video{
            max-width: 100%;
            height: auto;
            max-height: none;
        }
    }
`;