import styled from "styled-components";

export const CardContainer = styled.section`
    background: rgba(241, 240, 240, 0.8);
    padding: .75rem;
    display: flex;
    flex-direction: column;
    gap:1.25rem;
    width: 100%;
    border-radius: 10px;
    height: 100%;
`;

export const ContainerImage = styled.div`
    width: 100%;
    overflow: hidden;
    //height: ${props => props.entireImage ? "auto": "18rem"};
    height: 18rem;
    max-height: 18rem;
    display: flex;
    align-items: ${props => props.centerImage ? "center": "start"};
    justify-content: center;

    img{
        max-width: ${props=>props.centerImage ? "150%" : "100%"};
        max-height: ${props => props.entireImage ? "100%": "none"};
        min-height: ${props => props.centerImage ? "18rem" : "none"};
    }
`;

export const ContainerContent = styled.section`
    display: flex;
    flex-direction: column;

    height:auto;
    flex-grow: 1;
    
    .content__text{
        display: flex;
        align-items: center;
        gap:1rem;
        margin-bottom: 1rem;
        
        .text__title{
            font-size: 1.25rem;
            flex-grow: 1;
        }
    }

    .content__showmore{
        width: auto;
        height: auto;
        flex-shrink: 0;
        display:flex;
        align-items: flex-start;
        justify-content: center;

        .showmore__symbol{
            background: #878787;
            padding: 0.75rem;
            border-radius: 10px;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;

            p{
                color:#F1F0F0;
                font-weight: 900;
            }
        }
    }

    .content__desc p{
        font-size: 1rem;
        text-decoration: none;
        font-weight: 400 !important;
    }
`;