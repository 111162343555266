import { ContainerSlider, PoupUpSliderContainer } from "./styles"
import {ReactComponent as Close} from "../../../statics/images/cross.svg";

const ComponentPoupUpToRead = ({open, setOpen, images})=>{

    return(
        <PoupUpSliderContainer open={open}>
            <Close onClick={()=>setOpen(false)} className="close"/>
            <ContainerSlider>
                <img src={images[0]}/>
            </ContainerSlider>
        </PoupUpSliderContainer>
    )
}

export default ComponentPoupUpToRead;