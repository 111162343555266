import { Link } from "react-router-dom";
import { ContainerLogo, ContainerNavigation, HeaderContainer } from "./styles";
import {ReactComponent as Logo} from "../../../statics/images/logo.svg";
import ComponentFilter from "../filter";
import { useContext, useState } from "react";
import { LanguageContext } from "../../contexts/languageContext";

const ComponentHeader = ()=>{

    const {languageSelected, setLanguageSelected} = useContext(LanguageContext);

    return(
        <HeaderContainer>
            <ContainerLogo>
                <Link to="/">
                    <Logo className="logo"/>
                </Link>

                <div className="language">
                    <ComponentFilter avoidClear={true} name={languageSelected} selected={languageSelected} setSelected={setLanguageSelected} itemList={["pt-br", "en-us"]}/>
                </div>
            </ContainerLogo>

            <ContainerNavigation>
                <Link to="projects">{languageSelected == "pt-br" ? "Exposições" : "Exhibitions"}</Link>
                <Link to="portfolio">{languageSelected == "pt-br" ? "Portfólio" : "Portfolio"}</Link>
                <Link to="about">{languageSelected == "pt-br" ? "Bio" : "Bio"}</Link>
                <Link to="media">{languageSelected == "pt-br" ? "Mídia" : "Press"}</Link>
                <Link to="contact">{languageSelected == "pt-br" ? "Contato" : "Contact"}</Link>
                <ComponentFilter withoutBg={true} avoidClear={true} name={languageSelected} selected={languageSelected} setSelected={setLanguageSelected} itemList={["pt-br", "en-us"]}/>
            </ContainerNavigation>
        </HeaderContainer>
    )
}

export default ComponentHeader;