import { useQuery } from "@apollo/client";
import { ContainerContent, ContainerFilters, MediaContainer } from "./styles";
import { useContext, useEffect, useState } from "react";
import ComponentFilter from "../../common/components/filter";
import ComponentCard from "../../common/components/card";
import queryMedias from "../../service/queryMedias";
import ComponentPoupUpToRead from "../../common/components/poupUpToRead";
import { LanguageContext } from "../../common/contexts/languageContext";
import Pagination from "../../common/components/pagination";

const PageMedias = ()=>{
    const [years, setYears] = useState(null);
    const [yearSelected, setYearSelected] = useState(null);
    const [cardSelected, setCardSelected] = useState(null);
    const [currentData, setCurrentData] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const {languageSelected} = useContext(LanguageContext);
    const { loading, error, data: mediaData } = useQuery(queryMedias(yearSelected));
    
    useEffect(()=>{
        setCurrentPage(0);
    }, [yearSelected])
    useEffect(()=>{

        if(mediaData){
            getYears();
            const maxIndex= currentPage*12 + 12 > mediaData?.midias?.length -1 ? mediaData?.midias?.length -1: currentPage*12 + 12;
            console.log(maxIndex)
            setCurrentData([...mediaData?.midias.slice(currentPage*12, maxIndex)]);
        }
    }, [mediaData]);

    useEffect(()=>{

        if(mediaData){
            const maxIndex= currentPage*12 + 12 > mediaData?.midias?.length ? mediaData?.midias?.length : currentPage*12 + 12;

            //console.log("length: ", mediaData?.midias?.length, "current page: ", currentPage, "max index: ", maxIndex)

            setCurrentData([...mediaData?.midias.slice(currentPage*12, maxIndex)]);
        }
    },[currentPage])

    const getYears =()=>{
        const yearsList = mediaData?.midias?.map(project=> project.ano);
        const yearsSet = [... new Set(yearsList)];
        setYears(yearsSet);
    }

    const getImages = ()=>{
        const filtered = mediaData?.midias?.filter(item=>item.id == cardSelected)[0];
        const images = filtered?.capa?.url;
        return [images]
    }

    if(loading) return <p>Loading ...</p>;
    if(error) console.log(error);

    return(
        <MediaContainer>
            <ComponentPoupUpToRead images={getImages()} setOpen={setCardSelected} open={cardSelected}/>
            <ContainerFilters>
                <div className="filters__item">
                    {years && <ComponentFilter 
                        name={languageSelected == "pt-br" ? "ano" : "year"} 
                        itemList={["2021", "2022", "2023"]}
                        selected={yearSelected}
                        setSelected={setYearSelected}
                    />}
                </div>               
            </ContainerFilters>

            <ContainerContent>
                {currentData && currentData?.map(item=>(
                    <div 
                        onClick={()=>{
                            if(!item?.link){
                                setCardSelected(item.id)
                            }else{
                                window.open(item?.link, "__blank")
                            }
                        }} 
                        className="card"
                    >
                        <ComponentCard 
                            redirectTo={""} 
                            item={item}
                            showMore={false}
                            hideDesc={true}
                            hideTitle={true}
                        />
                    </div>
                ))}
            </ContainerContent>

            {mediaData?.midias?.length > 12 && <div className="pagination">
                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} page={Math.ceil(mediaData?.midias?.length/12)}/>
            </div>}
        </MediaContainer>
    )
}

export default PageMedias;