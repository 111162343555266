import { createContext, useState } from "react";

export const LanguageContext = createContext();

const LanguageContextProvider = ({children})=>{
    const [languageSelected, setLanguageSelected] = useState("pt-br");

    return(
        <LanguageContext.Provider value={{languageSelected, setLanguageSelected}}>
            {children}
        </LanguageContext.Provider>
    )
}

export default LanguageContextProvider;