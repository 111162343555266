import styled from "styled-components";

export const PoupUpArtContainer = styled.section`
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 500ms;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.85);
    transform-origin: top;
    transform: scaleY(${props=>props.open ? 1 : 0});
    z-index: 2;

    .close{
        position: absolute;
        top:2.5rem;
        right: 2.5rem;
        width: 2rem;
        fill: #F1F0F0;
        color: #F1F0F0;
        cursor: pointer;
        transition: 500ms;

        :hover{
            fill:red;
        }
    }
`;

export const ContainerSlider = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem;
    border-radius: 10px;
    background: #F1F0F0;
    height: 90vh;
    max-width: 90%;

    img{
        max-width: 100%;
        max-height: 100%;
    }

    @media screen and (max-width: 800px){
        max-height: none;
    }
`;