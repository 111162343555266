import { useParams } from "react-router-dom";
import { ContainerImagesList, ContainerTexts, ProjectContainer } from "./styles"
import { useQuery } from "@apollo/client";
import queryProjectBySlug from "../../service/queryProjectBySlug";
import { useContext, useEffect, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { LanguageContext } from "../../common/contexts/languageContext";

const PageProjectBySlug = ()=>{
    const {slug} = useParams();
    const [text, setText] = useState(null);
    const {loading, error, data} = useQuery(queryProjectBySlug(slug));

    useEffect(()=>{
        setText(data?.projeto?.curatorial?.split("\n"));
    },[data]);

    if(loading) return <p>loading...</p>
    if(error) return <p>erro</p>

    return(
        <ProjectContainer>
            <ContainerTexts>
                <h1>{data?.projeto?.titulo}</h1>

                <ReactMarkdown children={data?.projeto?.curatorial} />
            </ContainerTexts>

            <ContainerImagesList>
                {data?.projeto?.galeria?.map(image=>{
                    let isVideo = image?.fileName.match(/.*\.mp4$/gmi);
                    console.log(image?.fileName)
                    if (isVideo == null){
                        return <img key={image.id} src={image.url}/>
                    }else{
                        return <video controls key={image.id} src={image.url}/>
                    }
                })}
            </ContainerImagesList>
        </ProjectContainer>
    )
}

export default PageProjectBySlug;