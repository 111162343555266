import {gql} from "@apollo/client"

const queryPortfolio = (year, language)=>gql`
    query QueryPortfolio {
        portfolios (where: ${year ? `{linguagem: "${language}", ano: ${year}}, first: 200, orderBy: ano_DESC` : `{linguagem: "${language}"}, first: 200, orderBy: ano_DESC`}) {
            ano
            capa {
                id
                url
            }
            id
            titulo
            descricao
            categoria
        }
    }
`;

export default queryPortfolio;