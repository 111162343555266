import { useQuery } from "@apollo/client"
import { AboutUsContainer, ContainerContent, ContainerFilter } from "./styles"
import queryAboutPage from "../../service/queryAboutPage"
import { useContext, useEffect, useState } from "react";
import ComponentFilter from "../../common/components/filter";
import ComponentCard from "../../common/components/card";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { LanguageContext } from "../../common/contexts/languageContext";


const PageAboutUs = ()=>{
    const {languageSelected} = useContext(LanguageContext);
    const {loading, error, data} = useQuery(queryAboutPage(languageSelected));
    const [filterContentList, setFilterContentList] = useState(null);
    const [itemSelected, setItemSelected] = useState(null);
    const [currentCard, setCurrentcard] = useState(null)

    useEffect(()=>{
        if(data){
            setFilterContentList(data?.abouts?.map(item=>item.titulo));
            setItemSelected(data?.abouts[0].titulo)
        }
    }, [data])

    useEffect(()=>{
        if(itemSelected){
            const item = data.abouts.filter(item=>item.titulo == itemSelected)[0];
            setCurrentcard({
                title: item.titulo,
                image: item.capa.url,
                body: item.texto
            })
        }
    }, [itemSelected])

    if(loading || !filterContentList || !itemSelected || !currentCard) <p>loading ...</p>
    if(error) console.log(error)

    return(
        <AboutUsContainer>
            <ContainerFilter>
                <ComponentFilter startOpen={true} avoidClear={true} itemList={filterContentList} selected={itemSelected} setSelected={setItemSelected}/>
            </ContainerFilter>

            {(currentCard) && (<ContainerContent>
                <div className="content__image">
                    <img src={currentCard.image}/>
                </div>

                <div className="content__text">
                    <h1>Samira Pavesi</h1>

                    <ReactMarkdown children={currentCard.body}/>
                </div>
            </ContainerContent>)}
        </AboutUsContainer>
    )
}

export default PageAboutUs;