import { HashRouter, Route, Routes } from "react-router-dom";
import PageHome from "./pages/home";
import GlobalStyle from "./common/foundations/globalStyle";
import ComponentHeader from "./common/components/header";
import PageProjects from "./pages/projects";
import PageMedias from "./pages/media";
import PageProjectBySlug from "./pages/projectBySlug";
import PageAboutUs from "./pages/aboutUs";
import PagePortfolio from "./pages/portfolio";
import ComponentFooter from "./common/components/footer";
import PageContact from "./pages/contact";


function App() {
  return (
    <div className="App">
      <HashRouter>
        <GlobalStyle/>
        <ComponentHeader/>
        <Routes>
          <Route path="/" element={<PageProjects/>}/>
          <Route path="projects" element={<PageProjects/>}/>
          <Route path="project/:slug" element={<PageProjectBySlug/>}/>
          <Route path="media" element={<PageMedias/>}/>
          <Route path="portfolio" element={<PagePortfolio/>}/>
          <Route path="about" element={<PageAboutUs/>}/>
          <Route path="contact" element={<PageContact/>}/>
        </Routes>
        <ComponentFooter/>
      </HashRouter>
    </div>
  );
}

export default App;
