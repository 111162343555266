import styled from "styled-components";
import contactBg from "../../statics/images/contact-bg.webp";

export const ContactContainer = styled.section`
    min-height: 100vh;
    //background: url(${contactBg}) center / cover no-repeat;
    background: #fefefe;
    display: flex;
    flex-direction: column;
    gap:2rem;
    justify-content: center;
    font-size: 1.5rem;
    color: #808080;
    padding: 0 40% 0 2rem;

    .poupup{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: rgba(0,0,0,0.85);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 500ms;
        transform-origin: top;
        transform: scaleY(${props=>props.open ? 1 : 0});

        .poupup__content{
            background: #F1F0F0;
            border-radius: 10px;
            padding: 2.5rem;
            color: #1E1E1E;
            position: relative;

            .close{
                position: absolute;
                top: -2rem;
                right: -2rem;
                font-size: 2rem;
                font-weight: bold;
                color: #F1F0F0;
                cursor: pointer;
            }
        }
    }

    >a{
        font-size: 1.5rem;
        font-weight: 900;
        text-shadow: 5px 5px 10px rgba(0,0,0,0.25);
        width: 80%;
        transition: 500ms;

        :hover{
            color: #000;
        }
    }

    .container__content{
        display: flex;
        align-items: center;
        gap: 2rem;
        filter: drop-shadow(2.5px 2.5px 5px rgba(0,0,0,0.2));


        .content__btn{
            background:  #F1F0F0;
            padding: 1.25rem;
            color: #1E1E1E;
            border-radius: 10px;
            cursor: pointer;
            transition: 500ms;

            :hover{
                filter: drop-shadow(5px 5px 10px rgba(0,0,0,0.25));
                background: #1E1E1E;
                color: #F1F0F0;
            }
        }

        .content__media{
            background:  #F1F0F0;
            padding: .5rem 1rem;
            border-radius: 10px;
            width: 75px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: 500ms;

            :hover{
                filter: drop-shadow(5px 5px 10px rgba(0,0,0,0.25));
                background: #1E1E1E;
                fill: #F1F0F0;
            }
        }

        svg{
            height: 100%;
            max-width: 100%;
        }
    }

    @media screen and (max-width: 800px){
        padding: 2rem;

        >a{
            text-align: center;
            font-size: 1.25rem;
        }

        .container__content{
            flex-wrap: wrap;
            justify-content: center;

            p{
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }
`;

export const ContainerForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    input, textarea{
        outline: none;
        border: none;
        background: none;
        border-bottom: 2px solid #000;
        padding: 0.5rem;
    }

    button{
        padding: 0.5rem;
        cursor: pointer;
    }
`;